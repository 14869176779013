import React from 'react';
import './homePage.css'
import { Component } from "react";


import ArrowLeft from '../../../images/shapes/leftArrow.gif'
import ArrowLeftAlt from '../../../images/shapes/leftArrow1.gif'
import ArrowRight from '../../../images/shapes/rightArrow.gif'
import ArrowRightAlt from '../../../images/shapes/rightArrow1.gif'

const HomeArt = ({ images, currentIndex, nextImage, prevImage }) => {
  const image = images[currentIndex];

  return (
      <div className="home-image-viewer-content" onClick={(e) => e.stopPropagation()}>
        {/* <div class="handle-left" onClick={prevImage}>
          <div class="arrow-left" />
        </div> */}

        <div className="home-image-container-big" >
            <a href={image} target='_blank'>
                <img src={image} alt={image} />
            </a>
        </div>
        
        {/* <div class="handle-right" onClick={nextImage}>
          <div class="arrow-right" />
        </div> */}

        <div className='home-image-info-bottom'>
          <div className='arrow left' onClick={prevImage}>
            {'<'}
          </div>
          <div className='arrow right' onClick={nextImage}>
            {'>'}
          </div>
        </div>
      </div>
  );
};

class Arrow extends Component{

  mouseOver = false;

  handleMouseOver = () => {
    this.imgElement.src = this.props.image;
    this.mouseOver = true;
  };

  handleMouseOut = () => {
    this.imgElement.src = this.props.imageHover;
    this.mouseOver = false;
  };

  render() {
    return (
      <div
        className="Arrow"
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
      >
        <img src={this.props.imageHover}ref={(img) => (this.imgElement = img)} />
      </div>
    );
  }


}

export default HomeArt;