/*
This file was generated by https://github.com/pmndrs/gltfjsx and then
customized manually. It uses drei's new useAnimations hook which extracts
all actions and sets up a THREE.AnimationMixer for it so that you don't have to.
All of the assets actions, action-names and clips are available in its output. 
*/

import { useEffect, useState, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { useGLTF, useTexture, useAnimations, useFBX, OrbitControls } from "@react-three/drei"
import { FBXLoader, MeshBasicMaterial, Texture } from "three";
import { selectNightmareEnabled, selectBoogieEnabled } from '../../../state/Quests/QuestSlice';
import { Provider, useSelector } from 'react-redux'


export default function PongoModel({ activeModel }) {
  // Fetch model and a separate texture
  const { nodes, animations, materials } = useGLTF(activeModel.model)
  // Extract animation actions
  const { ref, actions, names } = useAnimations(animations)
  // Hover and animation-index states
  const [hovered, setHovered] = useState(false)
  const [index, setIndex] = useState(4)

  // Change cursor on hover-state
  useEffect(() => void (document.body.style.cursor = hovered ? "pointer" : "auto"), [hovered])
  const boogieEnabled = useSelector((state)=>state.quest.boogieEnabled)
  
  // Change animation when the index changes
  useEffect(() => {

    if (boogieEnabled) {

      // Reset and fade in animation after an index has been changed
      actions[names[0]].play()
      // In the clean-up phase, fade it out
    } else {
      actions[names[0]].stop()
    }
  });
  
  return (
    <group ref={ref} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <primitive object={nodes.mixamorigHips} />
        <skinnedMesh
          castShadow
          receiveShadow
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => setIndex((index + 1) % names.length)}
          geometry={nodes.Cube004.geometry}
          skeleton={nodes.Cube004.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}>
          <meshStandardMaterial map={materials['Material.001'].map} map-flipY={false} skinning />
        </skinnedMesh>
      </group>

    </group>
  )
}